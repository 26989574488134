import React from 'react';
import Navbar from './Navbar';
import Footer from "./hooks/Footer";

const Commissioners = () => {
  return (
   <>
   <Navbar/>
   <div className='bg-[#1d3556] h-fit p-[50px]'>
        <div className='mx-auto md:max-w-7xl overflow-hidden'>
            <div>
                <p className='font-cormorant text-[48px] font-[400] text-white'>Commissioners and Staff</p>
            </div>

           <a href="/">
            <div>
                <p className='text-[14.9px] font-[400] text-white underline'>Home  <span>/</span> Commissioners</p>
            </div>
           </a>
        </div>
    </div>

    <div className='mx-auto md:max-w-7xl overflow-hidden pt-5 '>
        <div className='mx-2'>
            <p className='font-sans text-[17px] font-[400]'>
            The Commission is headed by five Commissioners, nominated by the President and confirmed by the Senate, each serving a seven-year term. No more than three Commissioners can be of the same political party. The President chooses one Commissioner to act as Chair.
            </p>


            {/* <div>
                <p className='pt-5'>
                Phantomassist employees will never demand money, make threats, tell you to transfer money, or promise to help you get money or a prize. Anyone who does is a scammer. Report them to Phantomassist <a href="/contact">
                <span className='text-[#005ea2] underline'>Phantomassist.org</span>
                </a>
                </p>
            </div> */}

            


            
        </div>



        <div className='pt-10'>
            <p className='font-cormorant text-[32px] font-[400] text-[#1d3557] mx-2'>Commissioners</p>
        </div>


        <div className='pt-5'>
            <p className='font-sans text-[17px] font-[400] mx-2'>
            The Commission is headed by five Commissioners, nominated by the President and confirmed by the Senate, each serving a seven-year term. No more than three Commissioners can be of the same political party. The President chooses one Commissioner to act as Chair.
            </p>
        </div>

        <div className='mx-auto md:max-w-7xl overflow-hidden'>
            <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                <div className='bg-gren-500 h-fit com__border'>
                    <div className='flex'>
                        <div className='bg-ed-400 h-fit w-fit'>
                            <div>
                                <img src="https://www.ftc.gov/system/files/attachments/commissioners/kahn_180.jpg" alt="" />
                            </div>
                        </div>
                        <div className='bg-yellow-30 h-[100%] w-full pt-5'>
                            <div className='pl-5'>
                                <p className='text-[17px] font-semibold text-[#005ea2] underline'>Lina M. Khan</p>

                                <div className='pt-4'>
                                    <p className='font-semibold'>Chair</p>
                                </div>

                                <div className='pt-4'>
                                    <p className='font-semibold'>Sworn in: June 15, 2021</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-gren-500 h-fit com__border'>
                    <div className='flex'>
                        <div className='bg-ed-400 h-fit w-fit'>
                            <div>
                                <img src="https://www.ftc.gov/system/files/attachments/commissioners/commissioner-rebecca-kelly-slaughter_225.jpg" alt="" />
                            </div>
                        </div>
                        <div className='bg-yellow-30 h-[100%] w-full pt-5'>
                            <div className='pl-5'>
                                <p className='text-[17px] font-semibold text-[#005ea2] underline'>Rebecca Kelly Slaughter</p>

                                <div className='pt-4'>
                                    <p className='font-semibold'>Commissioner</p>
                                </div>

                                <div className='pt-4'>
                                    <p className='font-semibold'>Sworn in: May 2, 2018</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-gren-500 h-fit com__border'>
                    <div className='flex'>
                        <div className='bg-ed-400 h-fit w-fit'>
                            <div>
                                <img src="https://www.ftc.gov/system/files/ftc_gov/images/bedoya-225a.png" alt="" />
                            </div>
                        </div>
                        <div className='bg-yellow-30 h-[100%] w-full pt-5'>
                            <div className='pl-5'>
                                <p className='text-[17px] font-semibold text-[#005ea2] underline'>Alvaro Bedoya</p>

                                <div className='pt-4'>
                                    <p className='font-semibold'>Commissioner</p>
                                </div>

                                <div className='pt-4'>
                                    <p className='font-semibold'>Sworn in: May 16, 2022</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-gren-500 h-fit com__border'>
                    <div className='flex'>
                        <div className='bg-ed-400 h-fit w-fit'>
                            <div>
                                <img src="https://www.ftc.gov/system/files/ftc_gov/images/Commissioner-MHolyoak-225.jpg" alt="" />
                            </div>
                        </div>
                        <div className='bg-yellow-30 h-[100%] w-full pt-5'>
                            <div className='pl-5'>
                                <p className='text-[17px] font-semibold text-[#005ea2] underline'>Melissa Holyoak</p>

                                <div className='pt-4'>
                                    <p className='font-semibold'>Commissioner</p>
                                </div>

                                <div className='pt-4'>
                                    <p className='font-semibold'>Sworn in: March 25, 2024</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className='pt-10'>
        <Footer/>
    </div>
   </>
  )
}

export default Commissioners;