import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { BsStarFill } from "react-icons/bs";


const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="carouselx h-fit">
       

      <Slider {...settings} className='dragging mt-10' >

      <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://iran-tc.com/wp-content/uploads/2020/08/%D8%B4%D8%A7%D9%BE%D8%B1%DA%A9.png" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                            <p className="font-semibold">Robert Mitchell</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    Knigthackzz ( Cyber Specialist ) expertise was a lifeline when my family faced a blackmail attempt involving private photos. As 
                    a father of two from Ontario, I needed to safeguard our privacy and end the threat.
                    Knigthackzz ( Cyber Specialist ) guidance and careful investigation led to swift action, ensuring the safety of my family's privacy. 
                    His legal support proved invaluable in putting an end to the ordeal.
                    I highly recommend  Knigthackzz ( Cyber Specialist ) services to anyone in a similar situation. His dedication and strategic approach
                     are unmatched in dealing with cyber threats and blackmail.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 '>
                <p className='text-[#b0b0b0]'>Ontario Canada</p>
            </div>
            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'></p>
            </div>
                    
           </div>
        </div>

        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://media.sciencephoto.com/image/c0309894/800wm" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Emma Harrison</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-10'>
                    For years, I was in an online relationship with a man named Stephan on Instagram. He promised marriage and, out of love, I 
                    lent him $564,000. He assured me he'd repay, but he abruptly blocked me. Fortunately, I found  Knigthackzz ( Cyber Specialist )
                    through a recommendation. With minimal effort, I provided them the scammer's details, and they swiftly recovered my 
                    money. I'm immensely grateful to them.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'>United Kingdom </p>
            </div>
                    
           </div>
        </div>
        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl8jFLKULZzDOaRgvfejuv_Xjx9NzVF9mpRP_2POYndL4DsWZHAioaTCUTpW7tje9AGrc&usqp=CAU" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                            <p className="font-semibold">Davies Bennett</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    As a young professional from the UK targeted by a cyber blackmailer,  Knigthackzz ( Cyber Specialist ) intervention was my saving grace. 
                    Not only did he expose the fraudster's identity, but he also helped me regain control over my personal and professional 
                    life.
                    Our collaboration showcased the potential of expertise and determination to thwart deceitful schemes.  Cyber specialist
                     guidance empowered me to confront the challenge head-on, ultimately turning the tables on the blackmailer.
                     I'm immensely thankful for  Knigthackzz ( Cyber Specialist ) support in restoring my security and confidence.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'>USA • 12 </p>
            </div>
                    
           </div>
        </div>


        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://static3.machteamsoft.ro/thumbnails_med/profile/537/13767537_4546938416crop_1672.jpg" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Jasper Finnegan</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    As an Australian university student confronted with compromising photos being exploited against me,  Knigthackzz ( Cyber Specialist )
                    swift action and legal assistance were a lifeline. His intervention empowered me to reclaim control over my narrative, 
                    proving that the images were shared without consent.
                    Knigthackzz ( Cyber Specialist ) dedication led to a safer online presence for me, allowing me to regain a sense of security. His support
                     throughout the process was invaluable in restoring my peace of mind and asserting my rights.
                     I'm grateful for  Knigthackzz ( Cyber Specialist ) expertise in turning a distressing situation into an opportunity for resolution and empowerment.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'> Canada </p>
            </div>
                    
           </div>
        </div>
        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTL7Kmri1RB0ZHjMddMGTUAuxMZyeyYwLlvrw&usqp=CAU" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Johnson Dexter</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    Navigating a distressing situation where a blackmailer threatened to expose my personal history was daunting for 
                    me as a single mother from the USA. Knigthackzz ( Cyber Specialist ) guidance became my beacon of hope, empowering me to protect my 
                    family's reputation and security.
                    With  Knigthackzz ( Cyber Specialist ) expertise, I transformed fear into triumph and showcased the potential to overcome adversity. 
                    Together, we asserted our rights and turned the tables on the threat.
                    Knigthackzz ( Cyber Specialist ) support was a pivotal factor in my journey from vulnerability to empowerment. His commitment to 
                    safeguarding my family's dignity and freedom was truly remarkable.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-2 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-2'>
                <p className='text-[#b0b0b0]'> Australia</p>
            </div>
                    
           </div>
        </div>
       
      
      </Slider>

      <div className='text-center pt-10 pb-10'>
        <p>swipe to see more.</p>
      </div>
    </div>
  );
}

export default Review;