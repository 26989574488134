import React from 'react';
import Navbar from './Navbar';
import { HiOutlineUser, HiOutlineMail, HiOutlineGlobeAlt, HiOutlinePencilAlt } from 'react-icons/hi';
import { useForm } from "react-hook-form";


const Contact = () => {
    const {
        register,
        trigger,
        formState: { errors },
      } = useForm();
    
      const onSubmit = async (e) => {
        console.log("~ e", e);
        const isValid = await trigger();
        if (!isValid) {
          e.preventDefault();
        }
      };
  return (
    <>
    <Navbar/>
     <div className='Contact'>
        <div className='mx-auto md:max-w-7xl overflow-hidden  pt-[10%] xl:p-[10%] p-[15%]'>
            <div className='grid xl:mt-0 ml-1 mr-1 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 sm:grid-cols-1 md:grid-cols-1'>
                <div className='bg-gren-600 h-fit  z-10'>
                    <div>
                        <p className='xl:text-[48px] text-[35px] text-white font-[400] font-cormorant'>Contact Cyber specialist</p>
                    </div>

                    <a href="/">
                        <div>
                            <p className='text-[14.9px] font-[400] text-white underline'>Home <span>/</span> Service</p>
                        </div>
                    </a>
                </div>
                <div className='bg-gree-600 h-fit'>

                </div>
            </div>
        </div>
    </div>


    <div className='mx-auto md:max-w-7xl overflow-hidden'>
        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
            <div>
            <div className='bg-[#eeeded]'>
        <form action="https://formsubmit.co/f3a012c2262f0cd412b506aa5723b51f"
          onSubmit={onSubmit}
          method="POST">

        
        <div className="flex flex-col justify-center items-center w-full h-screen " >
      <div className="max-w-md w-full bg-white p-6 rounded-lg shadow-md formx">
        {/* <h2 className="text-2xl font-bold mb-4"></h2> */}

        <div className="mb-4">
          <div className="flex items-center mb-2">
            <HiOutlineUser className="text-gray-500 mr-2" />
            <label htmlFor="name" className="font-medium text-gray-700">Name</label>
          </div>
          <input
            type="text"
            id="name"
            className="w-full border-gray-300 border rounded-md px-4 py-2 focus:outline-none focus:border-[#0A68F1]"
            {...register("Name", {
              required: true,
              maxLength: 100,
            })} required/>
        </div>
        {errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 text-white mb-2">
                    {errors.email.type === "required" && "Your Name Is Required."}
                    {/* {errors.email.type === "pattern" && "Invalid email address."} */}
                  </p>
                  </div>
                    )}

        <div className="mb-4">
          <div className="flex items-center mb-2">
            <HiOutlineMail className="text-gray-500 mr-2" />
            <label htmlFor="email" className="font-medium text-gray-700">Email</label>
          </div>
          <input
            type="email"
            id="email"
            className="w-full border-gray-300 border rounded-md px-4 py-2 focus:outline-none focus:border-[#0A68F1]"
            {...register("email", {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            })} required/>
        </div>
        {errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 mb-2 text-white ">
                    {errors.email.type === "required" && "Your Email Is Required."}
                    {errors.email.type === "pattern" && "Invalid email address."}
                  </p>
                  </div>
                    )}

        <div className="mb-4">
          <div className="flex items-center mb-2">
            <HiOutlineGlobeAlt className="text-gray-500 mr-2" />
            <label htmlFor="country" className="font-medium text-gray-700">Country</label>
          </div>
          <input
            type="text"
            id="country"
            className="w-full border-gray-300 border rounded-md px-4 py-2 focus:outline-none focus:border-[#0A68F1]"
            {...register("Country", {
              required: true,
              maxLength: 2000,
            })} required/>
        </div>
        {errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 mb-2 text-white ">
                    {errors.email.type === "required" && "Your Country Is Required."}
                    {/* {errors.email.type === "pattern" && "Invalid email address."} */}
                  </p>
                  </div>
                    )}

        <select id="my-select"
      {...register("selection", {
        required: true,
        
      })} className='border border-gray-300 rounded pl-2 pr-5 py-2 w-full focus:outline-none focus:ring-2 text-black focus:border-[#0A68F1] mb-5' required>
        <option value="" className='text-[gray]'>Select Service</option>
        <option value="Restore Lost Crypto">Restore Lost Crypto</option>
        <option value="Romance Scam Recovery">Romance Scam Recovery</option>
        <option value="Bitcoin Recovery">Bitcoin Recovery</option>
        <option value="Wallet Tracking">Wallet Tracking</option>
        <option value="Spy">Spying</option>
        <option value="Social Media Hack">Social Media hack/Recovery</option>
        <option value="Icloud Unlock">Icloud Unlock</option>
        <option value="Account Activation">Account Activation</option>
        <option value="BlackMail/Extortion">BlackMail/Extortion</option>
        <option value="USDT Recovery/Hack">USDT Recovery/Hack</option>
        <option value="Whatsapp Hack/Spy">Whatsapp Hack/Spy</option>
        <option value="Gmail Recovery/Hack">Gmail Recovery/Hack</option>
        <option value="Password Recovery">Password Recovery</option>
        <option value="Background Check Removal">Background Check Removal</option>
        <option value="Tracking">Tracking</option>
        <option value="Phoen Unlock">Phone Unlock</option>
        <option value="Narcissist Victims Support Health Line">Narcissist Victims Support Health Line</option>
      </select>

        <div className="mb-4">
          <div className="flex items-center mb-2">
            <HiOutlinePencilAlt className="text-gray-500 mr-2" />
            <label htmlFor="description" className="font-medium text-gray-700">Describe your situation</label>
          </div>
          <textarea
            id="description"
            className="w-full border-gray-300 border rounded-md px-4 py-2 focus:outline-none focus:border-[#0A68F1]"
            rows={4}
            {...register("Message", {
              required: true,
              maxLength: 2000,
            })} required/>
        </div>
        {errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 text-white ">
                    {errors.email.type === "required" && "Message is Required"}
                    {/* {errors.email.type === "pattern" && "Invalid email address."} */}
                  </p>
                  </div>
                    )}

        <button
          type="submit"
          className="bg-[#0A68F1] text-white rounded-md py-2 px-4 hover:bg-[white] hover:text-black 
          hover:border border-[#0A68F1] transition duration-300"
        >
          Submit
        </button>
      </div>
    </div>
    </form>
            </div>
            </div>
           
            
                <div className=""> 
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53089383.18714171!2d-138.06823843517054!3d35.69377179964879!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sng!4v1693352094557!5m2!1sen!2sng"
                      width="100%"
                      height="100%"
                      title="Google Maps"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            
        </div>
     </div>
    
    </>
  )
}

export default Contact