import React from 'react';
import Navbar from './Navbar';
import Footer from "./hooks/Footer";


const Policy = () => {
  return (
    <>
    <Navbar/>
    <div className='bg-[#1d3556] h-fit p-[50px]'>
        <div className='mx-auto md:max-w-7xl overflow-hidden'>
            <div>
                <p className='font-cormorant text-[48px] font-[400] text-white'>Policy</p>
            </div>

           <a href="/">
            <div>
                <p className='text-[14.9px] font-[400] text-white underline'>Home  <span>/</span> policy</p>
            </div>
           </a>
        </div>
    </div>

    <div className='mx-auto md:max-w-7xl overflow-hidden pt-5 '>
        <div className='mx-2'>
            <p className='font-sans text-[17px] font-[400]'>
                We work to advance government policies that protect consumers and promote competition.
            </p>


            <div>
                <p className='pt-5'>
                As part of its policy, advocacy, and research work, the agency:
                </p>
            </div>

            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>files advocacy letters</p>
               </div>
            </div>
            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>files amicus briefs to aid in court deliberations</p>
               </div>
            </div>
            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>gives Congressional testimony</p>
               </div>
            </div>
            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>solicits and reviews public comments regarding rules, cases, and policies</p>
               </div>
            </div>
            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>Refund Policy</p>
               </div>
            </div>
            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>Accuracy</p>
               </div>
            </div>
            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>Privacy Policy</p>
               </div>
            </div>


           
        </div>



        
        <div className='pt-5 mx-3'>
            <p className='font-sans text-[17px] font-[400]'>
            Knigthackzz ( Cyber Specialist ) also works with competition and consumer protection agencies around the world to promote cooperation and sound policy approaches. Cyber specialist  built strong relationships with its counterparts abroad, and helps countries around the world to develop and enhance their competition and consumer protection programs.
            </p>
        </div>
    </div>


    <div className='pt-10'>
   <Footer/>
   </div>
    
    </>
  )
}

export default Policy;