import React, { useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { RiMenu3Fill } from 'react-icons/ri';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { Avatar } from '@mui/material';
import { BsBank2 } from "react-icons/bs";
import { IoShieldHalfSharp } from "react-icons/io5";


const Navbar = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [isCalenderVisible, setIsCalenderVisible] = useState(false);
  const [isInfoDropdownOpen, setIsInfoDropdownOpen] = useState(false);

    const toggleInfoDropdown = () => {
        setIsInfoDropdownOpen(!isInfoDropdownOpen);
    };

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const toggleBoxVisibility = (event) => {
    event.preventDefault();
    setIsBoxVisible(!isBoxVisible);
    setIsAboutVisible(false);
    setIsCalenderVisible(false);
  };

  const toggleAboutVisible = (event) => {
    event.preventDefault();
    setIsAboutVisible(!isAboutVisible);
    setIsBoxVisible(false);
    setIsCalenderVisible(false);
  };

  const toggleCalenderVisible = (event) => {
    event.preventDefault();
    setIsCalenderVisible(!isCalenderVisible);
    setIsBoxVisible(false);
    setIsAboutVisible(false);
  };

  return (
    <>
    <div className='bg-[#f0f0f0] w-full h-fit'>
            <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1'>
                <div className='ml-4'>
                    <span className='text-[15px] text-sm'>
                        🇺🇸 U.S. flag An official website of the United States government
                    </span>
                </div>
                <div className='text-start xl:ml-0 ml-5'>
                    <span
                        className='text-sm underline text-[#1867a7] flex gap-1 cursor-pointer'
                        onClick={toggleInfoDropdown}
                    >
                        Here's how you know
                        <span className='pt-1 pb-2'>
                            {isInfoDropdownOpen ? <FaAngleUp /> : <FaAngleDown />}
                        </span>
                    </span>
                    {isInfoDropdownOpen && (
                        <div className='mt-2 p-2 bg-white border  rounded shadow-md mb-4 w-full'>
                            <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-2 sm:grid-cols-1 md:grid-cols-1'>
                                <div className="flex items-start bg-[#f0f0f0] p-4 rounded-lg shadow-sm ">
                                    <Avatar className="!bg-transparent !border-2 !border-blue-500 !w-12 !h-12 flex justify-center items-center mr-4">
                                        <BsBank2 color="#2278c3" size="24" />
                                    </Avatar>
                                <div>
                                    <p className="font-bold text-black mb-1">The .com means it’s official.</p>
                                        <p className="text-sm text-gray-700">
                                            Federal organization websites often end in .com or .mil. Before sharing sensitive information, make sure you’re on a federal government site.
                                        </p>
                                </div>
                                </div>
                                <div className='bg-gren-600 h-fit'>
                                <div className="flex items-start bg-[#f0f0f0] p-4 rounded-lg shadow-sm">
                                    <Avatar className="!bg-transparent !border-2 !border-[#719f2a] !w-12 !h-12 flex justify-center items-center mr-4">
                                        <IoShieldHalfSharp color="#719f2a" size="24" />
                                    </Avatar>
                                <div>
                                    <p className="font-bold text-black mb-1">The site is secure.</p>
                                        <p className="text-sm text-gray-700">
                                        The <span className='font-semibold'>https://</span> ensures that you are connecting to the official website and that any information you provide is encrypted and transmitted securely.
                                        </p>
                                </div>
                                </div>
                                </div>
                            </div>  
                        </div>
                    )}
                </div>
            </div>
        </div>
    <div className="flex justify-between items-center bg-[white] shadow-md text-white px-4 py-3">
        
        <div className="ml-4">
          <div>
              <img src="Screenshot_2024-08-31_at_11.51.18_PM-removebg-preview.png" alt="" className='w-[65px]'/>
          </div>
        </div>
  
        <div className="hidden md:flex xl:flex lg:flex">
          <ul className="flex gap-5">
            <li>
              <a href="/" className="hover:text-[#055cb4] text-[#1d3557]">
                Home
              </a>
            </li>
  
            <li className="relative">
              <a
                href="/"
                className={`hover:text-[#055cb4] text-[#1d3557] ${isAboutVisible ? 'red-border' : ''}`}
                onClick={toggleAboutVisible}
              >
                About
              </a>
              <div className={`absolute left-0 mt-2 w-48 rounded-md bg-white border border-gray-300 p-4 z-10 pop-up-box ${isAboutVisible ? 'show' : ''}`}>
               <a href="/commissioners">
                <p className="text-black pop-up-item">Commissioners</p>
               </a>
                
                <a href="/bureaus">
                  <p className="text-black pop-up-item">Bureaus & Offices</p>
                </a>
              </div>
            </li>
  
            <li className="relative">
              <a
                href="/"
                className={`hover:hover:text-[#055cb4] text-[#1d3557] ${isBoxVisible ? 'red-border' : ''}`}
                onClick={toggleBoxVisibility}
              >
               Policy
              </a>
              <div className={`absolute left-0 mt-2 w-48 rounded-md bg-white border border-gray-300 p-4 z-10 pop-up-box ${isBoxVisible ? 'show' : ''}`}>
                <p className="text-black pop-up-item">Testimony</p>
                <a href="/policy">
                  <p className="text-black pop-up-item">Top Policy</p>
                </a>
                <a href="/international">
                  <p className="text-black pop-up-item">International</p>
                </a>
              </div>
            </li>
  
            <li className="relative">
              <a
                href="/"
                className={`hover:text-[#055cb4] text-[#1d3557] ${isCalenderVisible ? 'red-border' : ''}`}
                onClick={toggleCalenderVisible}
              >
                Service
              </a>
              <div className={`absolute left-0 mt-2 w-48 rounded-md bg-white border border-gray-300 p-4 z-10 pop-up-box ${isCalenderVisible ? 'show' : ''}`}>
                <p className="text-black pop-up-item"></p>
                <a href="/crypto">
                  <p className="text-black pop-up-item">Crypto Recovery</p>
                </a>
                <a href="/romance">
                  <p className="text-black pop-up-item">Romance Scam Recovery</p>
                </a>
               <a href="/social">
                  <p className="text-black pop-up-item">Social Media Spy</p>
               </a>
               <a href="/blackmail">
                  <p className="text-black pop-up-item">Help From Blackmail</p>
               </a>
                <a href="/">
                <p className="text-black pop-up-item">Fake Investment Recovery</p>
                </a>
              </div>
            </li>
            
            <li>
              <a href="/contact" className="hover:text-[#055cb4] text-[#1d3557]">Contact</a>
            </li>
           
            <li>
              <a href="https://knigthackzz.com/signup/login"  target='_blank' rel="noreferrer"  className="hover:text-[#055cb4] text-[#1d3557]">Login</a>
            </li>
          </ul>
        </div>
  
        <a href="https://knigthackzz.com/signup/" target='_blank' rel="noreferrer">
        <div className="hidden xl:flex lg:flex md:flex mr-4">
          <button className="bg-[#1D3557] hover:bg-[#055CB4]  text-white font-bold py-2 px-4 rounded">
            <div className="flex gap-2">
              <span className="pt-1 text-[white]">
                <FaUserCircle />
              </span>
             <p className='text-[white]'>Signup</p> 
            </div>
          </button>
        </div>
        </a>
  
        <div className="flex xl:hidden lg:hidden md:hidden text-[#1D3557]">
          <RiMenu3Fill size={27} onClick={toggleSidebar} className="cursor-pointer" />
        </div>
  
        <div
          className={`fixed bottom-0 left-0 w-[70%] h-[100%] transform ${openSidebar ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out sidebar z-50`}
        >
          <div className="flex justify-between items-center px-4 py-3">
            <div className='sidebar-header'>
              <p className='font-bold text-white text-xl'>Cyber specialist </p>
            </div>
            <button onClick={toggleSidebar} className="text-[white] text-xl">&times;</button>
          </div>
  
          <div className="px-4">
            <a href="/">
            <div className='sidebar-item'>
              <p>Home</p>
            </div>
            </a>
            <div className='sidebar-item'>
            <ul className="">
              <a
                href="/"
                className={`text-[white]  ${isAboutVisible ? 'red-border' : ''}`}
                onClick={toggleAboutVisible}
              >
                About
              </a>
              <div className={`absolute left-0 mt-2 w-48 rounded-md bg-white border border-gray-300 p-4 z-10 pop-up-box ${isAboutVisible ? 'show' : ''}`}>
               <a href="/commissioners">
                <p className="text-black pop-up-item">Commissioners</p>
               </a>
                
                <a href="/bureaus">
                  <p className="text-black pop-up-item">Bureaus & Offices</p>
                </a>
              </div>
            </ul>
            </div>
           <a href="/policy">
            <div className='sidebar-item'>
              <p>Policy</p>
            </div>
           </a>
            
            <a href="/contact">
            <div className='sidebar-item'>
              <p>Contact</p>
            </div>
            </a>
            <div className='sidebar-item'>
            <ul className="relative">
              <a
                href="/"
                className={` text-[white] ${isCalenderVisible ? 'red-border' : ''}`}
                onClick={toggleCalenderVisible}
              >
                Service
              </a>
              <div className={`absolute left-0 mt-2 w-48 rounded-md bg-white border border-gray-300 p-4 z-10 pop-up-box ${isCalenderVisible ? 'show' : ''}`}>
                <p className="text-black pop-up-item"></p>
                <a href="/crypto">
                  <p className="text-black pop-up-item">Crypto Recovery</p>
                </a>
                <a href="/romance">
                  <p className="text-black pop-up-item">Romance Scam Recovery</p>
                </a>
               <a href="/social">
                  <p className="text-black pop-up-item">Social Media Spy</p>
               </a>
               <a href="/blackmail">
                  <p className="text-black pop-up-item">Help From Blackmail</p>
               </a>
                <a href="/">
                <p className="text-black pop-up-item">Fake Investment Recovery</p>
                </a>
              </div>
            </ul>
            </div>

            <a href="https://knigthackzz.com/signup/" target='_blank' rel="noreferrer">
            <div className='sidebar-item'>
              <p>Signup</p>
            </div>
            </a>

            <a href="https://knigthackzz.com/signup/login" className='sidebar-item'  target='_blank' rel="noreferrer">
            <div className='sidebar-item'>
              <p>Login</p>
            </div>
            </a>

          </div>
        </div>
      </div>
    </>
   
  );
};

export default Navbar;
