import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { BsStarFill } from "react-icons/bs";


const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="carouselx h-fit">
       

      <Slider {...settings} className='dragging mt-10' >

      <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://s3-media1.fl.yelpcdn.com/photo/3_XRHrLZ5gbmYccoxc5BfQ/o.jpg" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                            <p className="font-semibold">Evangeline Stanton</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    Knigthackzz ( Cyber Specialist ) is a true savior! After falling victim to a fake hacker scam, I was devastated and desperate to recover 
                    my funds so i met a fake recovery frim and they took $40,000 from me claiming to restore my money back. Thankfully, 
                    Knigthackzz ( Cyber Specialist ) stepped in with his expertise and dedication. He tirelessly pursued the scammers, traced 
                    the transactions, and managed to reclaim my money. His professionalism and commitment to justice are unmatched.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 '>
                <p className='text-[#b0b0b0]'> USA</p>
            </div>
            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'></p>
            </div>
                    
           </div>
        </div>

        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQewlnu3DEn-BGBc0B2zp8Uq4SuqwUPejlsQqiTRefo2nEW0ucFS9R_PW7Z2u5Q1bbdrcw&usqp=CAU" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className="font-semibold"> finnian mitchell</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-10'>
                    Words can't express my gratitude to  Cyber specialist. I was duped by a hacker promising services to restore my disabled
                    instagram  & Facebook account but ended 
                    up losing $18,000. Knigthackzz ( Cyber Specialist ) not only recovered my funds but also provided invaluable 
                    guidance on how to enhance my cybersecurity. His knowledge and unwavering support are commendable.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'>  USA</p>
            </div>
                    
           </div>
        </div>
        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://s.yimg.com/ny/api/res/1.2/xf8iX0vrLG_hRHBiQCMwVA--/YXBwaWQ9aGlnaGxhbmRlcjt3PTY0MDtoPTgwMA--/https://media-mbst-pub-ue1.s3.amazonaws.com/creatr-uploaded-images/2019-11/a1191270-1314-11ea-9fcb-96817ed7091e" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                            <p className="font-semibold">Roslind Mercer</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    I was hesitant to reach out after being scammed by a fake hacker, but  Knigthackzz ( Cyber Specialist )
                    reputation reassured me. He lived up to every expectation. Not only did he recover my 
                    money efficiently, but he also educated me on the signs of cyber scams.  Knigthackzz ( Cyber Specialist ) is a beacon 
                    of hope in the fight against fraudulent hackers
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'>USA</p>
            </div>
                    
           </div>
        </div>


        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://media.istockphoto.com/id/160409804/photo/portrait-of-an-adult-man.jpg?s=612x612&w=0&k=20&c=HcFtrZkbbu4FU_jl9Id3Uk_vQV5lexKhtR3QUw6QMz8=" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                            <p className="font-semibold">Matteo</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    Knigthackzz ( Cyber Specialist ) is a lifesaver! After falling for a fake hacker's trap, After Scamming $10,000 worth of Gift Card from me
                     I thought I'd never see my money again. 
                    But  Knigthackzz ( Cyber Specialist ) thorough investigation and strategic approach not only led to the recovery of my funds but also 
                    gave me a sense of justice. He's a reliable ally in the battle against cybercrime.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'> Canada</p>
            </div>
                    
           </div>
        </div>
        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3w88hDts35gSbYX6dmCyHYKoW6uVLF9jgJQ&s" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className="font-semibold">Jaxton Turner</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    Knigthackzz ( Cyber Specialist ) is a true guardian of justice in the digital realm. I was left in shambles after 
                    a fake hacker swindled me of $56,000 and he keep on asking for more money.  Knigthackzz ( Cyber Specialist ) approach was not just about recovering 
                    the funds, but also about restoring my faith. His meticulous investigation and strategic prowess led 
                    to the return of what was rightfully mine. In a world fraught with cyber dangers,  Knigthackzz ( Cyber Specialist ) stands as a 
                    beacon of hope and expertise.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-2 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-2'>
                <p className='text-[#b0b0b0]'> Australia</p>
            </div>
                    
           </div>
        </div>
       
      
      </Slider>

      <div className='text-center pt-10 pb-10'>
        <p>swipe to see more.</p>
      </div>
    </div>
  );
}

export default Review;