import React, { useState } from 'react';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import InvestReview from './hooks/InvestReview';
import Footer from "./hooks/Footer";

const FakeInvestment = () => {
    const Accordion = ({ title, content }) => {
        const [isOpen, setIsOpen] = useState(false);
      
        return (
          <div className="border rounded shadow mb-4">
            <div
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-200"
              onClick={() => setIsOpen(!isOpen)}
            >
              <h2 className="text-lg font-semibold">{title}</h2>
              <svg
                className={`w-6 h-6 transition-transform duration-300 transform text-[#162e51] ${
                  isOpen ? 'rotate-45' : ''
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={isOpen ? 'M6 18L18 6' : 'M6 18L18 6M6 6l12 12'}
                />
              </svg>
            </div>
            {isOpen && (
              <div className="p-4 bg-white">
                <p>{content}</p> <br />
              </div>
            )}
          </div>
        );
      };
  return (
   <>
   <Navbar/>
   <div className='Invest__class'>
        <div className='mx-auto md:max-w-7xl overflow-hidden  pt-[10%] xl:p-[7%] p-[12%]'>
            <div className='grid xl:mt-0 ml-1 mr-1 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 sm:grid-cols-1 md:grid-cols-1'>
                <div className='bg-gren-600 h-fit  z-10'>
                    <div>
                        <p className='xl:text-[48px] text-[35px] text-white font-[400] font-cormorant'>Fake Investment Recovery</p>
                    </div>

                    <a href="/">
                        <div>
                            <p className='text-[14.9px] font-[400] text-white underline'>Home <span>/</span> Service</p>
                        </div>
                    </a>
                </div>
                <div className='bg-gree-600 h-fit'>

                </div>
            </div>
        </div>
    </div>

    <div className='mx-auto md:max-w-6xl overflow-hidden '>
        <div className='pt-10 px-1'>
            <p className='font-cormorant text-[32px] font-[400] text-[#1d3557] uppercase'>WE SPECIALIZE IN Fake Investment Recovery.</p>
        </div>

        <p className='px-2 pt-5 font-sans text-[17px] font-[400]'>
        Knigthackzz ( Cyber Specialist ) specializes in assisting individuals in recovering from investment scams. Our expert team is well-versed in navigating the complexities of financial fraud to provide swift and effective solutions. We understand the distress, frustration, and financial hardship that comes with being a victim of an investment scam, and we are here to help you through every step of the recovery process. Our team consists of seasoned professionals with years of experience in financial recovery, legal processes, and cybersecurity. We leverage our extensive knowledge and resources to trace and recover your lost funds efficiently. At  Cyber specialist, we are committed to restoring not only your finances but also your confidence and peace of mind.
        </p>

        <p className='px-2 pt-5 font-sans text-[17px] font-[400]'>
        With a proven track record of successful recoveries, we approach each case with personalized attention and a tailored strategy to ensure the best possible outcome. We believe that no one should have to face the aftermath of financial fraud alone. If you've fallen victim to an investment scam, don't despair. Contact us today for a confidential consultation and let us help you begin the process of reclaiming your lost funds. Together, we can take the first step towards your financial recovery and safeguard your future.
        </p>
    </div>

    <div className='tower__img mt-10'>
        <div className='mx-auto md:max-w-7xl overflow-hidden '>
            <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 sm:grid-cols-1 md:grid-cols-1 pt-5 pb-10'>
                <div className='bg-gren-600 h-fit z-10 mt-5 boder__end'>
                    <p className='xl:text-[34px] text-[30px]  font-cormorant font-[400] text-white text-center'>Sign up for quick money recovery.</p>

                    
                </div>
                <div className='bg-green-60 h-fit z-10 mt-5'> 
                <a href="https://knigthackzz.com/signup/" target='_blank' rel='noreferrer'>
                    <div className='flex justify-center pt-5 xl:pt-0'>
                        <button className='bg-[#162e51] text-white p-5 w-[30%]'>Register</button>
                    </div>
                </a>

                    {/* <div className='flex justify-center gap-3'>
                        <div>
                         <FaInstagram size={24} color='white'/>
                        </div>

                        <div>
                            <TfiTwitter  size={24} color='white'/>
                        </div>
                        <div>
                            <MdOutlineEmail  size={24} color='white'/>
                        </div>
                        <div>
                            <FaTelegramPlane  size={24} color='white'/>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>

    <div className='mx-auto md:max-w-7xl overflow-hidden'>
        <div className='pt-5 ml-5'>
            <p className='font-cormorant text-[32px] font-[400]'>Top Services</p>
            <div className='action__borderx'></div>
        </div>




        <div className='mx-auto overflow-hidden md:max-w-7xl text-white'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-0 pb-10 pt-[5%]'>
                    <div className='bg-green-00 h-fit border__sev border-l border-[#c7ccd5] border-b'>
                    <div className='bg-[#0097d7] h-fit w-fit relative top-40 pb-1 pt-1 text-[#0097d7]'>h</div>
                        <div className='pl-5 pr-2'>
                        
                        <motion.div className=''
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.2, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <p className='font-cormorant text-[22px] font-[400] text-[#1d3557] pb-5 '>
                            Fake Initial Coin Offerings Recovery
                            </p>
                        </motion.div>

                       

                       
                        </div>
                    </div>
                    <div className='bg-green-00 h-fit border__sev border-l border-[#c7ccd5] border-b '>
                        <div className='bg-[#0097d7] h-fit w-fit relative top-40 pb-1 pt-1 text-[#0097d7]'>h</div>
                        <div className='pl-5 pr-2'>
                        
                        <motion.div className=''
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.2, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <p className='font-cormorant text-[22px] font-[400] text-[#1d3557] pb-5 '>
                            Boiler Room Scams Recovery
                            </p>
                        </motion.div>

                       

                       
                        </div>
                    </div>
                    <div className='bg-green-00 h-fit border__sev border-l border-gray-300 border-b '>
                        <div className='bg-[#0097d7] h-fit w-fit relative top-40 pb-1 pt-1 text-[#0097d7]'>h</div>
                        <div className='pl-5 pr-2'>
                        
                        <motion.div className=''
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.2, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <p className='font-cormorant text-[22px] font-[400] text-[#1d3557] pb-5 '>
                            Help from
                            manipulation
                            </p>
                        </motion.div>

                       

                       
                        </div>
                    </div>
                    <div className='bg-green-00 h-fit border__sev border-l border-gray-300 border-b '>
                        <div className='bg-[#0097d7] h-fit w-fit relative top-40 pb-1 pt-1 text-[#0097d7]'>h</div>
                        <div className='pl-5 pr-2'>
                        
                        <motion.div className=''
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.2, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <p className='font-cormorant text-[22px] font-[400] text-[#1d3557] pb-5'>
                            Affinity Fraud  Recovery
                            </p>
                        </motion.div>

                       

                       
                        </div>
                    </div>
                    <div className='bg-green-00 h-fit border__sev border-l border-gray-300 border-b '>
                        <div className='bg-[#0097d7] h-fit w-fit relative top-40 pb-1 pt-1 text-[#0097d7]'>h</div>
                        <div className='pl-5 pr-2'>
                        
                        <motion.div className=''
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.2, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <p className='font-cormorant text-[22px] font-[400] text-[#1d3557] pb-5 '>
                            Offshore Investment Scams Recovery
                            </p>
                        </motion.div>

                       

                       
                        </div>
                    </div>
                    <div className='bg-green-00 h-fit border__sev border-l border-gray-300 border-b '>
                        <div className='bg-[#0097d7] h-fit w-fit relative top-40 pb-1 pt-1 text-[#0097d7]'>h</div>
                        <div className='pl-5 pr-2'>
                        
                        <motion.div className=''
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.2, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <p className='font-cormorant text-[22px] font-[400] text-[#1d3557] pb-5 pt-1'>
                            Binary Options Scams Recovery
                            </p>
                        </motion.div>

                    
                        </div>
                    </div>
                    <div className='bg-green-00 h-fit border__sev border-l border-gray-300 border-b '>
                        <div className='bg-[#0097d7] h-fit w-fit relative top-40 pb-1 pt-1 text-[#0097d7]'>h</div>
                        <div className='pl-5 pr-2'>
                        
                        <motion.div className=''
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.2, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <p className='font-cormorant text-[22px] font-[400] text-[#1d3557] pb-5 '>
                            Fake Hedge Funds or Private Equity  Recovery
                            </p>
                        </motion.div>

                    
                        </div>
                    </div>
                    <div className='bg-green-00 h-fit border__sev border-l border-gray-300 border-b '>
                        <div className='bg-[#0097d7] h-fit w-fit relative top-40 pb-1 pt-1 text-[#0097d7]'>h</div>
                        <div className='pl-5 pr-2'>
                        
                        <motion.div className=''
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.2, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <p className='font-cormorant text-[22px] font-[400] text-[#1d3557] pb-5 '>
                            Ponzi Schemes Recovery
                            </p>
                        </motion.div>

                    
                        </div>
                    </div>
                    <div className='bg-green-00 h-fit border__sev border-l border-gray-300 border-b '>
                        <div className='bg-[#0097d7] h-fit w-fit relative top-40 pb-1 pt-1 text-[#0097d7]'>h</div>
                        <div className='pl-5 pr-2'>
                        
                        <motion.div className=''
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.2, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <p className='font-cormorant text-[22px] font-[400] text-[#1d3557] pb-5 '>
                            24 Hrs
                            Protect
                            </p>
                        </motion.div>

                    
                        </div>
                    </div>
                </div>
            </div>
    </div>

    <div className='bg-[#f0f0f0] h-fit mt-5'>
        <div className='mx-auto md:max-w-7xl overflow-hidden'>
            <div className='pt-5 ml-5'>
                <p className='font-cormorant text-[32px] font-[400]'>Top Testimonies</p>
                <div className='action__borderx'></div>
            </div>
            <InvestReview/>
        </div>
    </div>

    <div className='mx-auto overflow-hidden md:max-w-7xl'>
    <div className='pt-5 ml-5'>
                <p className='font-cormorant text-[32px] font-[400]'>F. A. Q.</p>
                <div className='action__borderx'></div>
            </div>
                        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2  xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-4 pb-10 pt-[3%]'>
                            <div className='bg-green-00 h-fit'>
                                <Accordion
                                    title="100% Refund Policy"
                                    content=" Knigthackzz ( Cyber Specialist ) is our unwavering commitment to delivering superior results and 
                                    meeting the high expectations of our valued clients. While we have full confidence in our ability 
                                    to accomplish the given tasks, we acknowledge that unforeseen circumstances may occasionally arise, 
                                    potentially impacting our ability to honor our commitments. In such cases, if we find ourselves 
                                    unable to fulfill a task, we stand by our commitment to customer satisfaction by offering a refund, 
                                    ensuring trust in our brand
                                    At the heart of our operations lie principles of transparency and fairness in all our dealings. Our 
                                    terms and conditions are thoughtfully designed to provide clarity and protection for both our clients 
                                    and our team. Our dedicated focus remains on delivering exceptional service and value to our clients. 
                                    Continuously, we strive to improve our processes, consistently surpassing expectations.
                                    "
                                />
                                <Accordion
                                   title="How does your recovery firm operate?"
                                    content="Our firm, specializing in scam recovery, is steadfast in its commitment to aiding victims of 
                                    fraudulent schemes throughout the intricate process of reclaiming their lost funds. Employing a 
                                    multi-faceted approach, we merge specialized expertise, cutting-edge technology, and legal resources 
                                    to offer the utmost support to our clients.
                                    Central to our operations is a team comprising seasoned professionals with a niche focus on the complex 
                                    realm of scam recovery. These adept experts harness a blend of investigative methodologies and financial
                                     expertise to meticulously unravel the intricate web of transactions associated with the scam. 
                                     Through exhaustive tracking of the financial trail, we meticulously pinpoint and segregate the assets 
                                     rightfully belonging to you.
                                    "
                                />
                            </div>
                            <div className='bg-green600 h-fit'>
                                <Accordion 
                                    title="Can I Trust This Firm?" 
                                    content="We acknowledge the pivotal importance of trust and authority when it comes to our clients 
                                    feeling secure and confident in sharing sensitive financial information with our team. We are fully 
                                    aware that the decision to entrust us with such critical information necessitates careful 
                                    consideration, a responsibility we take with the utmost seriousness.
                                    We firmly believe that trust is not simply given, but earned through a continuous display of 
                                    consistent and dependable performance. Our commitment lies in surpassing our clients' expectations 
                                    in every facet of our work, striving to foster an environment of reliability and excellence."
                                    
                                    />
                                <Accordion 
                                    title="How Long dose the process take?" 
                                    content="The process of recovering lost Cryptocurrency spans a variable timeline, fluctuating between 
                                    durations as short as one hour and potentially extending to two months or more. This duration may seem 
                                    extensive due to our comprehensive initial search conducted within the first week of onboarding a 
                                    client. After this exhaustive search, we retain the encrypted private backup and employ password 
                                    guesses to perpetuate testing, identifying new recovery vectors, and enhancing our hardware 
                                    capabilities.
                                    Respecting the privacy and security of our clients, we maintain client information unless expressly 
                                    requested for deletion. Our commitment to safeguarding client privacy remains unwavering, adhering 
                                    to strict protocols in data management
                                    " 
                                />
                            </div>
                        </div>
         </div>

        
         <div>
            <Footer/>
        </div>
   </>
  )
}

export default FakeInvestment;