import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { BsStarFill } from "react-icons/bs";


const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="carouselx h-fit">
       

      <Slider {...settings} className='dragging mt-10' >

      <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://pickledplum.com/wp-content/uploads/2016/09/short-hair.jpg" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                            <p className="font-semibold">Emily W.</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    I was devastated after falling victim to a Ponzi scheme that promised high returns with minimal risk. Thanks to  Cyber specialist, I was able to trace my lost funds and recover a significant portion of my investment. Their expertise and dedication were instrumental in getting my money back. I highly recommend their services to anyone who has been scammed.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 '>
                <p className='text-[#b0b0b0]'> USA</p>
            </div>
            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'></p>
            </div>
                    
           </div>
        </div>

        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://images.unsplash.com/photo-1562612618-76db0faf7c40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8MzAlMjB5ZWFyJTIwb2xkJTIwbWFufGVufDB8fDB8fHww&w=1000&q=80" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className="font-semibold">Atticus</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-10'>
                    After being lured into an advance fee fraud, I thought I had lost all hope.  Knigthackzz ( Cyber Specialist ) stepped in and provided exceptional legal assistance, negotiating with the scammers and helping me reclaim my funds. Their team was professional, supportive, and relentless in their pursuit of justice. I can't thank them enough for their help.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'>  USA </p>
            </div>
                    
           </div>
        </div>
        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://images.unsplash.com/photo-1615332327357-ce22631750a6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8MzUlMjB5ZWFyJTIwb2xkJTIwd29tYW58ZW58MHx8MHx8fDA%3D&w=1000&q=80" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                            <p className="font-semibold">Meg</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    Knigthackzz ( Cyber Specialist ) was a lifesaver when I got caught in a fake ICO scam. They quickly identified the fraudulent activity, coordinated with law enforcement, and managed to recover my lost investment. Their comprehensive forensic financial analysis was key to unraveling the scam. I am incredibly grateful for their swift and effective action.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'>USA</p>
            </div>
                    
           </div>
        </div>


        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://media.istockphoto.com/id/160409804/photo/portrait-of-an-adult-man.jpg?s=612x612&w=0&k=20&c=HcFtrZkbbu4FU_jl9Id3Uk_vQV5lexKhtR3QUw6QMz8=" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                            <p className="font-semibold">Matteo</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    Falling prey to a romance scam left me devastated, both emotionally and financially. Then came  Cyber specialist. 
                    His meticulous investigation and legal expertise not only uncovered the scam but also restored my lost funds. 
                    Knigthackzz ( Cyber Specialist ) turned my nightmare into a story of triumph. His dedication is unmatched, and I am truly indebted to him.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'> Canada</p>
            </div>
                    
           </div>
        </div>
        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://thumbs.dreamstime.com/b/handsome-years-old-man-glasses-outdoors-30858353.jpg" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className="font-semibold">Benjamin</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    I was a victim of a pyramid scheme that preyed on my trust in a close-knit community.  Knigthackzz ( Cyber Specialist ) provided the education and resources I needed to understand the scam and take action. Their team not only helped me recover my funds but also empowered me to protect myself from future scams. Their support was invaluable.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-2 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-2'>
                <p className='text-[#b0b0b0]'> Australia</p>
            </div>
                    
           </div>
        </div>
       
      
      </Slider>

      <div className='text-center pt-10 pb-10'>
        <p>swipe to see more.</p>
      </div>
    </div>
  );
}

export default Review;