import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { BsStarFill } from "react-icons/bs";


const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="carouselx h-fit">
       

      <Slider {...settings} className='dragging mt-10' >

      <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://farm9.staticflickr.com/8205/8198004356_689e22102b_b.jpg" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                            <p className="font-semibold">Elara Montgomery</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    After falling victim to a heartless romance scam, I lost $90,000.  Knigthackzz ( Cyber Specialist ) was my savior.
                     With his expertise, he tirelessly pursued the culprits and successfully recovered every penny. His dedication 
                     and determination turned my despair into relief. I am forever grateful to  Knigthackzz ( Cyber Specialist ) and his team for restoring my 
                     financial security.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 '>
                <p className='text-[#b0b0b0]'>Texas • USA </p>
            </div>
            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'></p>
            </div>
                    
           </div>
        </div>

        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://image1.masterfile.com/getImage/NjAwLTA2Nzg2ODUzZW4uMDAwMDAwMDA=ABul8M/600-06786853en_Masterfile.jpg" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Jerry Stewart</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-10'>
                    For years, I was in an online relationship with a man named Stephan on Instagram. He promised marriage and, out of love, I 
                    lent him $564,000. He assured me he'd repay, but he abruptly blocked me. Fortunately, I found  Cyber specialist
                    through a recommendation. With minimal effort, I provided them the scammer's details, and they swiftly recovered my 
                    money. I'm immensely grateful to them.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'> Dakota • USA </p>
            </div>
                    
           </div>
        </div>
        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://cdn.cliqueinc.com/posts/285200/beauty-routine-age-35-285200-1580400238322-square.700x0c.jpg" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                            <p className="font-semibold">Seraphina Bennett</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    Being scammed by someone I thought I knew shattered my world.  Knigthackzz ( Cyber Specialist ) swift 
                    action not only exposed the deception but also helped me regain my finances. His unwavering 
                    support during the recovery process was remarkable. I can't thank  Knigthackzz ( Cyber Specialist ) enough for giving me a 
                    second chance and ensuring justice prevailed.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'>USA</p>
            </div>
                    
           </div>
        </div>


        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://qph.cf2.quoracdn.net/main-qimg-6e9251509ce15466f5378a823b686210-lq" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Jasper Finnegan</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    Falling prey to a romance scam left me devastated, both emotionally and financially. Then came  Cyber specialist. 
                    His meticulous investigation and legal expertise not only uncovered the scam but also restored my lost funds. 
                    Knigthackzz ( Cyber Specialist ) turned my nightmare into a story of triumph. His dedication is unmatched, and I am truly indebted to him.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'> Canada</p>
            </div>
                    
           </div>
        </div>
        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                            <img src="https://hips.hearstapps.com/hmg-prod/images/gettyimages-694016643-1523641375.jpg?resize=480:*" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Penny Blossom</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    I never imagined a romance scam would rob me of my savings and trust. Thankfully,  Knigthackzz ( Cyber Specialist ) stepped in. His 
                    strategic approach and determination brought justice to my case. With  Knigthackzz ( Cyber Specialist ) on my side, I not only regained my 
                    money but also my confidence. He's a true advocate for those who have been wronged.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-2 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-2'>
                <p className='text-[#b0b0b0]'> Australia</p>
            </div>
                    
           </div>
        </div>
       
      
      </Slider>

      <div className='text-center pt-10 pb-10'>
        <p>swipe to see more.</p>
      </div>
    </div>
  );
}

export default Review;