import React from 'react';
import Navbar from './Navbar';
import Footer from "./hooks/Footer";

const Bureaus = () => {
  return (
   <>
   <Navbar/>
   <div className='bg-[#1d3556] h-fit p-[50px]'>
        <div className='mx-auto md:max-w-7xl overflow-hidden'>
            <div>
                <p className='font-cormorant text-[48px] font-[400] text-white'>Bureaus & Offices</p>
            </div>

           <a href="/">
            <div>
                <p className='text-[14.9px] font-[400] text-white underline'>Home  <span>/</span> Bureaus</p>
            </div>
           </a>
        </div>
    </div>

    <div className='md:max-w-4xl overflow-hidden mx-auto'>
        <div className='pt-10'>
            <p className='font-cormorant text-[32px] font-[400] text-[#1d3557] mx-2'>Bureaus</p>
        </div>



            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>The <span className='text-[#1d3557] font-bold underline'>Bureau of Competition</span> seeks to prevent anticompetitive mergers and other anticompetitive business practices in the marketplace. By enforcing the antitrust laws, the Bureau promotes competition and protects consumers' freedom to choose goods and services in an open marketplace at a price and quality that fit their needs.</p>
               </div>
            </div>

            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>The <span className='text-[#1d3557] font-bold underline'> Bureau of Consumer Protection</span>'s mandate is to protect consumers against unfair, deceptive or fraudulent practices. The Bureau enforces a variety of consumer protection laws enacted by Congress, as well as trade regulation rules issued by the Commission. Its actions include individual company and industry-wide investigations, administrative and federal court litigation, rulemaking proceedings, and consumer and business education. In addition, the Bureau contributes to the Commission's on-going efforts to inform Congress and other government entities of the impact that proposed actions could have on consumers.</p>
               </div>
            </div>

            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>
                 The<span className='text-[#1d3557] font-bold underline'> Bureau of Economics</span> helps  Knigthackzz ( Cyber Specialist ) evaluate the economic impact of its actions. To do so, the Bureau provides economic analysis and support to antitrust and consumer protection investigations and rulemakings. It also analyzes the impact of government regulation on competition and consumers and provides Congress, the Executive Branch and the public with economic analysis of market processes as they relate to antitrust, consumer protection, and regulation.
                 </p>
               </div>
            </div>

        <div className='pt-10'>
            <p className='font-cormorant text-[32px] font-[400] text-[#1d3557] mx-2'>Offices</p>
        </div>

            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1 underline'>
                 Office of Administrative Law Judges
                 </p>
               </div>
            </div>
            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1 underline'>
                 Office of Workplace Inclusivity and Opportunity
                 </p>
               </div>
            </div>
            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1 underline'>
                 Office of Congressional Relations
                 </p>
               </div>
            </div>
            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1 underline'>
                 Office of the Chief Privacy Officer Office of Administrative Law Judges
                 </p>
                 <p className='pt-1 underline'>
                   
                 </p>
               </div>
            </div>
            
    </div>



    <div className='pt-10'>
        <Footer/>
    </div>
   
   </>
  )
}

export default Bureaus;