import './App.css';
import { Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from "./Components/Home";
import International from "./Components/International";
import Policy from './Components/Policy';
import Commissioners from './Components/Commissioners';
import Bureaus from  "./Components/Bureaus";
import Crypto from './Components/Crypto';
import Romance from './Components/Romance';
import FakeInvestment from "./Components/FakeInvestment";
import ReclaimFunds from "./Components/ReclaimFunds";
import Blackmail from './Components/Blackmail';
import Social from './Components/Social';
import Contact from "./Components/Contact";

function App() {
  return (
   <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/navbar' element={<Navbar/>}/>
      <Route path='/international' element={<International/>}/>
      <Route path='/policy' element={<Policy/>}/>
      <Route path='/commissioners' element={<Commissioners/>}/>
      <Route path='/bureaus' element={<Bureaus/>}/>
      <Route path='/crypto' element={<Crypto/>}/>
      <Route path='/romance' element={<Romance/>}/>
      <Route path='/fakeinvestment' element={<FakeInvestment/>}/>
      <Route path='/reclaimfunds' element={<ReclaimFunds/>}/>
      <Route path='/blackmail' element={<Blackmail/>}/>
      <Route path='/social' element={<Social/>}/>
      <Route path='/contact' element={<Contact/>}/>
   </Routes>
  );
}

export default App;
